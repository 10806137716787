<template>
    <div>
        <h1 class="page__title">Филиал</h1>

        <div class="intro-y box p-5 mt-5">
            <form method="post" class="grid grid-cols-12 gap-6" :class="{ 'disabled-form': loading }">
                <div v-if="loading" class="absolute z-100 left-0 top-0 w-full h-full flex items-center justify-center">
                    <Preloader />
                </div>

                <div class="col-span-6">
                    <label for="form-name" class="form-label">Название <sup>*</sup></label>
                    <input
                        id="form-name"
                        type="text"
                        class="form-control w-full"
                        placeholder=""
                        required
                        autocomplete="off"
                        v-model="form.name"
                    />
                </div>

                <div v-if="isSuperAdmin" class="col-span-6">
                    <label for="form-slug" class="form-label">Домен <sup>*</sup></label>
                    <input
                        id="form-slug"
                        type="text"
                        class="form-control w-full"
                        placeholder=""
                        required
                        autocomplete="off"
                        v-model="form.slug"
                    />
                </div>

                <div :class="{ 'col-span-6': !isSuperAdmin, 'col-span-12': isSuperAdmin }">
                    <label for="form-address" class="form-label">Адрес <sup>*</sup></label>
                    <input
                        id="form-address"
                        type="text"
                        class="form-control w-full"
                        placeholder=""
                        required
                        autocomplete="off"
                        v-model="form.address"
                    />
                </div>

                <div class="col-span-6">
                    <label for="form-video" class="form-label">Видео</label>
                    <input
                        id="form-video"
                        type="text"
                        class="form-control w-full"
                        placeholder=""
                        required
                        autocomplete="off"
                        v-model="form.video"
                    />
                </div>

                <div class="col-span-6">
                    <label for="form-map" class="form-label">Ссылка на карту</label>
                    <input
                        id="form-map"
                        type="text"
                        class="form-control w-full"
                        placeholder=""
                        required
                        autocomplete="off"
                        v-model="form.map"
                    />
                </div>

                <div v-if="isSuperAdmin" class="col-span-6 relative">
                    <label for="form-lat" class="form-label">Lat</label>
                    <input
                        id="form-lat"
                        type="number"
                        class="form-control w-full"
                        placeholder=""
                        required
                        autocomplete="off"
                        v-model="form.lat"
                    />
                </div>

                <div v-if="isSuperAdmin" class="col-span-6 relative">
                    <label for="form-long" class="form-label">Long</label>
                    <input
                        id="form-long"
                        type="number"
                        class="form-control w-full"
                        placeholder=""
                        autocomplete="off"
                        v-model="form.long"
                    />
                </div>

                <div class="col-span-6 relative">
                    <label for="form-email" class="form-label">Почта</label>
                    <input
                        id="form-email"
                        type="email"
                        class="form-control w-full"
                        placeholder=""
                        required
                        autocomplete="off"
                        v-model="form.email"
                    />
                </div>

                <div class="col-span-6">
                    <label for="form-phone1" class="form-label">Телефон 1</label>
                    <input
                        id="form-phone1"
                        type="text"
                        class="form-control w-full"
                        placeholder="+7 (###) ###-##-##"
                        required
                        autocomplete="off"
                        v-maska="'+7 (###) ###-##-##'"
                        v-model="form.phone1"
                    />
                </div>

                <div class="col-span-6">
                    <label for="form-phone2" class="form-label">Телефон 2</label>
                    <input
                        id="form-phone2"
                        type="text"
                        class="form-control w-full"
                        placeholder="+7 (###) ###-##-##"
                        required
                        autocomplete="off"
                        v-maska="'+7 (###) ###-##-##'"
                        v-model="form.phone2"
                    />
                </div>

                <div class="col-span-6">
                    <label for="form-vk" class="form-label">Вконтакте</label>
                    <input
                        id="form-vk"
                        type="text"
                        class="form-control w-full"
                        placeholder=""
                        required
                        autocomplete="off"
                        v-model="form.vk"
                    />
                </div>

                <div class="col-span-6">
                    <label for="form-tg" class="form-label">Телеграм</label>
                    <input
                        id="form-tg"
                        type="text"
                        class="form-control w-full"
                        placeholder=""
                        required
                        autocomplete="off"
                        v-model="form.tg"
                    />
                </div>

                <div class="col-span-6">
                    <label for="form-wapp" class="form-label">Ватсапп</label>
                    <input
                        id="form-wapp"
                        type="text"
                        class="form-control w-full"
                        placeholder=""
                        required
                        autocomplete="off"
                        v-model="form.wapp"
                    />
                </div>

                <div v-if="cities && cities.length > 0 && isSuperAdmin" class="col-span-6">
                    <label for="form-city" class="form-label">Город <sup>*</sup></label>
                    <div class="flex items-center">
                        <Multiselect
                            id="form-city"
                            name="type"
                            :value="null"
                            :searchable="true"
                            track-by="name"
                            label="name"
                            valueProp="id"
                            :options="cities"
                            :can-clear="false"
                            placeholder="Не выбрано"
                            noResultsText="Не найдено"
                            noOptionsText="Не найдено"
                            v-model="form.city_id"
                        />

                        <button type="button" class="ml-4" title="Ввести город" @click="handleToggleCityVisibility">
                            <PlusCircleIcon class="block text-primary-7" />
                        </button>
                    </div>

                    <div v-show="cityVisible" class="flex items-center mt-4">
                        <input
                            id="city-name"
                            type="text"
                            class="form-control w-full"
                            placeholder="Название города"
                            autocomplete="off"
                            v-model="cityName"
                            @keyup.enter="handleAddCity"
                        />

                        <button
                            type="button"
                            class="ml-4"
                            :class="{ 'disabled-form': !cityName || cityName.length < 1 }"
                            :disabled="!cityName || cityName.length < 1"
                            title="Добавить город"
                            @click="handleAddCity"
                        >
                            <ArrowRightCircleIcon class="block text-primary-7" />
                        </button>
                    </div>
                </div>

                <div v-if="affiliates && affiliates.length > 0 && isSuperAdmin" class="col-span-6">
                    <label for="form-affiliates" class="form-label">Филиал из холихопа <sup>*</sup></label>
                    <Multiselect
                        id="form-affiliates"
                        name="type"
                        :value="null"
                        :searchable="false"
                        track-by="name"
                        label="name"
                        valueProp="id"
                        :options="affiliates"
                        :can-clear="false"
                        placeholder="Не выбрано"
                        noResultsText="Не найдено"
                        noOptionsText="Не найдено"
                        v-model="form.hollihop_affiliate_id"
                    />
                </div>

                <div class="col-span-12">
                    <label class="form-label">Изображение (594 x 495)</label>
                    <CustomCropper
                        id="affiliate-edit-crop"
                        :aspect-ratio="594 / 495"
                        :min-canvas-width="594"
                        :min-canvas-height="495"
                        :src="imagePreview"
                        @on-crop="handleCropend"
                    />
                </div>

                <div class="col-span-12 flex items-center justify-end mt-5">
                    <router-link to="/affiliates" class="btn btn-secondary w-24 mb-2 mr-2"> Отмена </router-link>
                    <button type="submit" class="btn btn-primary mb-2 mr-2" @click.prevent="save(false)">
                        Сохранить и выйти
                    </button>
                    <button type="submit" class="btn btn-primary mb-2" @click.prevent="save(true)">Сохранить</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { helper as $h } from '@/utils/helper';
import { errorResponse, formPreparation } from '@/mixins/form/form-mixin';
import Preloader from '@/components/preloader/Main';
import UploadImage from '@/components/upload-image/UploadImage';
import CustomWyswig from '@/components/base/custom-wyswig/Main.vue';
import CustomCropper from '../../components/base/custom-cropper/CustomCropper';

export default {
    name: 'Edit',
    components: { CustomCropper, Preloader, UploadImage, CustomWyswig },
    mixins: [errorResponse, formPreparation],
    data() {
        return {
            loading: false,
            cities: [],
            affiliates: [],
            cityName: '',
            cityVisible: false,
            imagePreview: null,
            form: {
                name: '',
                slug: '',
                city_id: '',
                address: '',
                hollihop_affiliate_id: '',
                long: '',
                lat: '',
                phone1: '',
                phone2: '',
                vk: '',
                tg: '',
                wapp: '',
                video: '',
                email: '',
                map: '',
                _image_file: '',
            },
        };
    },
    async created() {
        await this.fetchData();
        await this.fetchCities();
        await this.fetchHolyHopeAffiliates();
    },
    computed: {
        ...mapGetters({
            getUser: 'user/getUser',
            isSuperAdmin: 'user/isSuperAdmin',
        }),
    },
    methods: {
        async fetchData() {
            this.loading = true;
            try {
                const { data } = await this.axios.get(`/affiliates/${this.$route.params.id}`);

                for (const field in this.form) {
                    if (field === 'phone1' || field === 'phone2') {
                        this.form[field] = $h.parsePhone(data.data[field]);
                    } else {
                        this.form[field] = data.data[field];
                    }
                }

                this.imagePreview = data.data.image ? data.data.image : '/images/default-affiliate.png';
                this.form._image_file = null;

                this.loading = false;
            } catch (ex) {
                console.error('cant find data: ' + ex);
                this.loading = false;
                this.$router.push('/error-page');
            }
        },
        async fetchCities() {
            try {
                const { data } = await this.axios.get('/cities');
                this.cities = data.data;
            } catch (ex) {
                console.error('cant fetch cities: ' + ex);
            }
        },
        async fetchHolyHopeAffiliates() {
            try {
                const { data } = await this.axios.get('/affiliates/get-hollihop-affiliates');
                this.affiliates = data;
            } catch (ex) {
                console.error('cant fetch holy hope affiliates: ' + ex);
            }
        },
        async save(flag) {
            try {
                const formData = this.formPreparation();

                await this.axios.post('/affiliates/' + this.$route.params.id, formData, {
                    params: {
                        _method: 'put',
                    },
                });
                if (flag) {
                    this.$notify('Запись сохранена');
                } else {
                    await this.$router.push({ name: 'affiliates' });
                }
            } catch (ex) {
                this.errorResponse(ex);
            }
        },
        handleToggleCityVisibility() {
            this.cityVisible = !this.cityVisible;
            const el = document.getElementById('city-name');
            if (el) {
                setTimeout(() => {
                    el.focus();
                }, 0.1);
            }
        },
        async handleAddCity() {
            this.loading = true;
            try {
                await this.axios.post('/cities', {
                    name: this.cityName.toLowerCase(),
                    name_en: $h.customTransliterate(this.cityName).toLowerCase(),
                });
                await this.$notify(`Город ${this.cityName} добавлен`);
                this.cityName = '';
                this.cityVisible = false;
                await this.fetchCities();
                const el = document.getElementById('form-city');
                if (el) {
                    setTimeout(() => {
                        el.focus();
                    }, 0.1);
                }
                this.loading = false;
            } catch (ex) {
                this.errorResponse(ex);
            }
        },
        handleCropend({ file, preview }) {
            this.form._image_file = file;
            this.imagePreview = preview;
        },
    },
};
</script>

<style scoped lang="scss">
.avatar-cropper {
    &::v-deep {
        .img-cropper {
            width: 594px;
            height: 495px;
        }
    }
}
</style>
